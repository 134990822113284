/* Usman Colors */
:root {
  --background-color: #e7e6ed;
  --text-light-black: #2b2b2b;
  --text-light-gray: #8c8c8c;
  --input-placeholder-color: #7a7a7a;
  --input-border-color: #e1e1e1;
  --text-color: #463c77;
}
/* Font Family */
.poppins-bold {
  font-family: PoppinsBold;
}
.poppins-regular {
  font-family: PoppinsReg;
}
.poppins-semibold {
  font-family: PoppinsSemiBold;
}
/* ______________________________________________ */
/* Logo Image */
.logo_img {
  position: absolute;
  top: 20px;
  left: 70px;
  right: 0;
  margin: auto;
  z-index: 1;
}
@media screen and (max-width: 576px) {
  .logo_img {
    position: absolute;
    top: 20px;
    left: 0;
    text-align: center;
    right: 0;
    margin: auto;
    z-index: 1;
  }
}
/* Sign-In Page Main Box */
.signin_page {
  background-color: var(--text-color);
  background-image: url("./assets/images/bg.png");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: 100vh;
  overflow: hidden;
}
/* Apply opacity only to the background image */
.signin_page::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: -1;
}
/* _____________________________________________________ */
.sign_in_box2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
@media screen and (max-width: 576px) {
  .sign_in_box2 {
    /* margin-top: 70%; */
  }
}
.sign_in_heading {
  color: var(--text-light-black);
}
.sign_in_paragraph {
  color: var(--text-light-gray);
  width: 95%;
}
/* Sign-In Page Input Field */
.container .sign_in_input {
  display: block;
  width: 100%;
  padding: 0.375rem 1.2rem;
  font-size: 1rem;
  font-weight: 400;
  height: 45px;
  border-radius: 7px;
  border: 1px solid var(--input-border-color);
}
.container .sign_in_input:focus,
.container .sign_in_input:active {
  display: block;
  width: 100%;
  padding: 0.375rem 1.2rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border: 1px solid var(--input-border-color);
  outline: 0;
  box-shadow: 0 0 0 0 white;
}
input::placeholder {
  color: input-placeholder-color !important;
}
.password-input {
  padding-right: 40px;
  border-radius: 7px;
}
/* ____________________________________ */
/* Sign-In Page Toggle Button */
.input-group {
  border-radius: 7px;
  background-color: yellow;
}
.password-toggle-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: white;
  cursor: pointer;
}
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-control,
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-select,
.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(
    .form-floating
  ) {
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
}
.form-switch .form-check-input {
  width: 45px;
  height: 25px;
}
/* Custom styling for the switch toggle */
.form-check-input:checked {
  background-color: var(--text-color);
}
.form-check-input:checked + .sign-in-toggle::before {
  background-color: white;
}

.form-check-input:checked + .sign-in-toggle::after {
  background-color: gray;
}
@media screen and (max-width: 576px) {
  .form-switch .form-check-input {
    width: 25px;
    height: 14px;
  }
}
.form-switch .form-check-label {
  font-size: 1.2rem;
}
.sign-in-toggle {
  color: var(--text-light-gray);
}
@media screen and (max-width: 576px) {
  .sign-in-toggle {
    font-size: 11px;
  }
  .sign-in-forgot {
    font-size: 12px;
  }
}
.sign-in-forgot {
  color: var(--text-color);
  text-decoration: none;
  cursor: pointer !important;
}
/* ____________________________________ */
/* Sign-In Page Button */
.sign_in_btn {
  background-color: var(--text-color);
  border: 2px solid var(--text-color);
  color: white;
}
.sign_in_btn:hover {
  color: var(--text-color);
  border: 2px solid var(--text-color);
}
/* ____________________________________ */
.main_box {
  border: 2px solid var(--text-light-gray);
  color: var(--text-light-gray);
  cursor: pointer;
  border-radius: 7px;
  width: 100%;
  padding: 0.53rem 1.2rem;
}
.main svg path {
  stroke: var(--text-light-gray);
}
.main_box:hover {
  border: 2px solid var(--text-color);
  color: var(--text-color);
}
.main_box.active {
  border: 2px solid var(--text-color);
  color: var(--text-color);
}
.main_box.active .main p {
  color: var(--text-color);
}
.main_box:hover svg path {
  stroke: var(--text-color);
}
.main_box.active svg path {
  stroke: var(--text-color);
}
.circle-checkbox {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--text-light-gray);
  position: relative;
}
.circle-checkbox.active .inner-circle {
  background-color: var(--text-color);
}
.circle-checkbox.active {
  border: 2px solid var(--text-color);
}
.inner-circle {
  width: 10px;
  height: 10px;
  background-color: transparent;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* ______________________________________ */
/* OTP Verification Page */
.otp_error_msg {
  color: red;
}
/* _______________________________________ */
/* Reset Password Page */
.reset_psw_paragraph {
  color: var(--text-light-gray);
}
/* _______________________________________ */
/* Forgot Password Page */
.forgot_paragraph {
  color: var(--text-light-gray);
}
/* _______________________________________ */
