* {
  user-select: none;
  /*disabled text selection on entire app.*/
}
:root {
  --bg-primary: #241763;
}
/* overriding default react picker transform prop */
.react-datepicker-popper {
  /* transform: translate(12.6667px, 206.667px) !important; */
  left: 20px !important;
}
.text-xs {
  font-size: 11px;
}
.text-gray {
  background-color: #ececec !important;
}
.text-gray-font {
  color: #ececec !important;
}
.dotGraph {
  height: 8px;
  width: 8px;
  background-color: var(--bg-primary);
  border-radius: 50%;
}
.donotPercentage {
  position: absolute;
  left: 50%;
  top: 43%;
  color: black;
  font-weight: bold;
  z-index: 99999;
  font-weight: bold;

  transform: translate(-50%, -50%);
}
.donotMatrics {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 4px 15px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  align-content: center;
  /* place-items: center; */

  width: 100%;
}
.subjectColor {
  height: 12px;
  width: 40px;
  border-radius: 5px;
}
.divider {
  height: 2px !important;
}
.bg-main {
  background-color: var(--bg-primary);
}
.fg-main {
  color: var(--bg-primary);
}
.bgInActive {
  background-color: #ebebeb;
}
a {
  text-decoration: none;
}
.cursor-pointer {
  cursor: pointer !important;
}
.customShadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-track {
  background: #412c7d;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb {
  background: #ebe5e5;
  border-radius: 0px;
}
.icon {
  cursor: pointer;
}
.rounded-md {
  border-radius: 15px;
}
.chipBg {
  background-color: #edebf1;
}
.borderRadius_15 {
  border-radius: 15px;
}
/* .customSidebarScroll {
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: var(--bg-primary);
  ::-webkit-scrollbar {
    width: 6px !important;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--bg-primary);
    border-radius: 12px;
  }
  ::-webkit-scrollbar-track {
    background-color: var(--bg-primary);
    border-radius: 4%;
  }
} */
/* .................<Sidebar starts>....................... */
.linksScroll {
  overflow-y: auto;
  height: 100%;
  max-height: 100%;
}

.sidebarOuterLg {
  position: relative;
  /* z-index: 50; */
  border-radius: 15px;
  background-color: transparent;
  height: 100vh;
  width: 22%;
  transition: width 0.2s linear;
}
.sidebarOuterSm {
  position: relative;
  /* z-index: 50; */
  /* margin: 5px 5px; */
  border-radius: 15px;
  background-color: transparent;
  height: 98.9vh;
  width: 8%;
  transition: width 0.2s linear;
}
.sidebarWrapperSm {
  /* position: relative; */
  margin: 5px 5px;
  border-radius: 15px;
  background-color: var(--bg-primary);
  height: 98.9vh;
  width: 6%;
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 40px 8px;
  transition: width 0.2s linear;
}
.sidebarWrapperLg {
  /* position: relative; */
  margin: 5px 5px;
  border-radius: 15px;
  background-color: var(--bg-primary);
  height: 98%;
  /* max-height: 100vh; */
  /* overflow-y: auto; */
  width: 17%;
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 40px 8px;
  transition: width 0.2s linear;
}
.smSidebarWrapper {
  position: relative;
  margin: 5px 5px;
  border-radius: 15px;
  background-color: var(--bg-primary);
  height: 98.9vh;
  width: 5%;
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 40px 8px;
}
.sidebarLogo img {
  width: 143px;
  height: 81px;
  max-width: 90%;
  object-fit: contain;
}
.divider {
  background-color: #979797b8;
  height: 1px;
  width: 100%;
}
.linksWrapper {
  height: 100%;
  /* background-color: red; */
  overflow-y: auto;
  overflow-x: hidden;
}
.linksWrapperMiniSidebar {
  height: 77vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.sidebarLink {
  /* height: 30px; */
  border-radius: 7px;
  padding: 10px 10px;

  cursor: pointer;
  color: white;
}
.sidebarLinkActive {
  border-radius: 7px;
  padding: 10px 10px !important;
  margin-right: 5px;
  cursor: pointer;
  color: white;
  background-color: #585085;
}
.sidebarLink:hover {
  background-color: #585085;
  margin-right: 5px;
}
.sidebarToggle {
  height: 30px;
  width: 30px;
  background-color: var(--bg-primary);
  position: absolute;
  right: -14px;
  top: 127px;
  display: flex;
  justify-content: centere;
  align-items: center;
  color: white;
  border: 1px solid var(--bg-primary);
  box-shadow: -6px 0px 22px 0px #191e4566;
  border-radius: 7px;
  cursor: pointer;
}
.sidebarToggleLeft {
  height: 30px;
  width: 30px;
  background-color: var(--bg-primary);
  position: absolute;
  left: -14px;
  top: 127px;
  display: flex;
  justify-content: centere;
  align-items: center;
  color: white;
  border: 1px solid var(--bg-primary);
  box-shadow: -6px 0px 22px 0px #191e4566;
  border-radius: 7px;
  cursor: pointer;
  rotate: 180deg;
}
.sidebarLinks {
  /* height: 100%; */
  /* background-color: red; */
  /* max-height: 100%; */
  overflow-y: scroll;
}
/* .................<Sidebar ends>....................... */
/* .................<Navbar Starts>....................... */

.navbarWrapper {
  /* height: 120px !important; */
  padding: 15px 15px 15px 15px;
  width: 100%;
  background: #fff;
  /* margin-bottom: 30px; */
}
.searchBox {
  height: 38px;
  border: 1px solid #5f5f5f4a;
  border-radius: 6px;
  background-color: #fff;
  width: 35%;
  cursor: pointer;
}
.searchBox svg {
  margin: 10px;
}
/* .ActionItems {
  width: 50%;
  justify-content: space-between;
} */
.routeName {
  width: 22%;
  height: 100%;
}
.menuItems {
  width: 40%;
  flex-grow: 1;
  height: 100%;
}
.navbarWrapper .searchBox input {
  outline: none;
  border: none;
}
.searchBoxWrapper {
  /* width: 49%; */
  height: 100%;
}
.chip {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #f93c65;
  top: -12px;
  right: -4px;
  padding: 10px;
}
.navAvatar {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #ebebeb;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.userSection {
  /* width: 100%; */
  /* flex-grow: 1; */
  white-space: nowrap;
  /* gap: 8px; */
}
/* .userDropdownIcon {
  position: absolute;
  right: -50px;
} */
.flagsDropdown {
  position: absolute;
  padding: 5px 10px;
  top: 24px;
  width: 130px;
  right: -4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
  height: 120px;
  max-height: 120px;
  background-color: #ebebeb;
  overflow-y: auto;
  border-radius: 7px;
  /* box-shadow: 0px 22px 0px #ebebeb; */
}
.flagList {
  /* background-color: red; */
  border-radius: 6px;
  cursor: pointer;
  padding: 4px 3px;
  gap: 5px;
}
.flagList:hover {
  background-color: rgb(214, 205, 205);
}
.setLanguage img {
  width: 30px;
  height: 20px;
}
/* .................<Navbar ends>....................... */

/* main section css */
.routesWrapper {
  padding: 15px;
}

.assesmentsWrapper {
  padding: 0 0;
}
/* main section css ends here*/

/* MINISIDEBAR CSS STARTS HERE */
.miniSidebar {
  position: absolute;
  background-color: var(--bg-primary);
  height: 100vh;
  max-height: 100vh;
  width: 0; /* Initially set to 0 */
  overflow: hidden;
  transition: width 0.5s ease; /* Apply transition */
  z-index: 999;
}

.miniSidebar.open {
  width: 80%; /* Open sidebar width */
}

.miniSidebar.closed {
  width: 0; /* Close sidebar width */
}

.hide {
  transform: translateX(-50%);
  transition: all 0.2s linear;
}
.closeIconWrapper {
  height: 30px;
  width: 30px;
  background-color: var(--bg-primary);
  position: absolute;
  right: -9px;
  top: 87px;
  display: flex;
  justify-content: centere;
  align-items: center;
  color: white;
  border: 1px solid #605888;
  box-shadow: -6px 0px 22px 0px #191e4566;
  border-radius: 7px;
  cursor: pointer;
}
/* MINISIDEBAR CSS ENDS HERE */

/* .........................<COURSE ROUTE CSS STARTS>........................ */
.filterDropdown {
  padding: 5px 10px;
  border: 1px solid #1b1b1b38;
  border-radius: 6px;
  background-color: white;
  font-size: 14px;
}
.filterationCourse {
  /* width: 20%; */
  /* background-color: white; */
  /* border-radius: 15px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px; */
}
.courseChip {
  padding: 4px 10px;
  border-radius: 16px;
  height: auto;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}
.courseInstructor {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  gap: 10px;
}
.courseInstructor h4 {
  font-size: 14px;
  color: --bg-primary;
  color: var(--bg-primary);
}
.courseInstructor h5 {
  color: gray;
  font-size: 14px;
}
.courseInstructor .userIcon {
  height: 14px;
  width: 14px;
}
.courseDescription {
  font-size: 14px;
}
.salesPrice {
  font-size: 22px;
  color: var(--bg-primary);
}
.normalPrice {
  font-size: 15px;
  text-decoration: line-through;
  color: #1b1b1b99;
}
.coursesWrapper {
  width: 80%;
}
.courseLists {
  display: flex;
  flex-wrap: wrap;
}
.courseCard {
  border-radius: 15px;
  background-color: white;
}
.filterSmSCreen {
  position: fixed;
  display: block !important;
  top: 0;
  left: 0;
  min-height: 100vh;
  overflow-y: auto;
  width: 100%;
  background: white;
  padding: 10px 10px;
  z-index: 9999;
}
.filterItemsWrapper .filterTitle {
  font-size: 14px;
}
.filterItemsWrapper label,
.totalViewsRating {
  font-size: 13px !important;
}
.starFilter .ratingPoint {
  font-size: 13px;
}
.starFilter .totalRatings {
  font-size: 13px;
}
/* .........................<COURSE ROUTE CSS ENDS>........................ */

/* BILAL CSS CODE FOR COURSE DETIALS */
/* bilal css  */

.second {
  background-color: #ffffff;
}
.video-dot {
  font-size: 14px;
  color: #1b1b1b99;
}
.video-time {
  color: --bg-primary;
  font-size: 14px;
}
.colored-data {
  background-color: #f9f9f9e5;
}
.colored-heading {
  color: #1b1b1b99;
}
.icon {
  color: #1b1b1b1a;
}
.icon-time {
  color: #1b1b1b1a;
  font-size: 14px;
}
.colored-time {
  color: --bg-primary;
  font-size: 14px;
  margin-bottom: 10px;
}
.colored-time-2 {
  color: #44ab96;
  font-size: 14px;
  margin-bottom: 10px;
}
.playing {
  background-color: --bg-primary;
}
.completed {
  background-color: #44ab96;
}
.viser-helper {
  color: --bg-primary;
}
.digital-studio {
  position: relative;
  bottom: 7px;
  font-size: 12px;
}
.last-text {
  color: #1b1b1b99;
  font-size: 12px;
}
.first-text {
  position: relative;
  bottom: 15px;
  color: #1b1b1b99;
}
.button {
  width: 230px;
  border-radius: 10px;
  border: 1px solid var(--bg-primary);
  background-color: white;
  padding: 10px;
  color: var(--bg-primary);
  font-weight: bold;
}
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
}
/* BILAL CSS CODE FOR COURSE DETIALS */
/* .......................<Cognitive assesment route css starts>..................... */
.instructionBox {
  background-color: var(--bg-primary);
  padding: 50px;
  color: white;
  /* gap: 20px; */
}
.instructionBox h4 {
  font-size: 40px;
  font-weight: 700;
  padding-bottom: 15px;
  text-align: start;
}
.instructionBox ul {
  list-style-type: none;
  padding: 0 !important;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.instructionBox ul li {
  font-size: 12px;
  font-weight: 300;
  color: #f0f0f0;
}
.instructionBox button {
  width: 100%;
  margin: 20px 0;
  padding: 15px 5px;
  border: none;
  border-radius: 8px;
}

/* .......................<Cognitive assesment route css ends>..................... */

/* ........................<Assesment route css starts>.............................. */
.assesmentWrapper {
  background-color: var(--bg-primary);
  min-height: 100vh;
}
.progressBarOuter {
  background-color: #6c6491 !important;
}
.progressBarInner {
  background-color: #ffffff !important;
}
.question {
  padding: 12px 12px 12px 12px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  display: flex;
  gap: 5px;
  align-items: center;
  background-color: #f9f9f9;
  cursor: pointer;
}
.selectedQuestion {
  padding: 12px 12px 12px 12px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  display: flex;
  gap: 5px;
  align-items: center;
  background-color: #f4f0ff;
  cursor: pointer;
  width: 100%;
}
.assesmentWrapper .btnSubmit {
  width: 100%;

  padding: 15px 5px;
  border: none;
  border-radius: 8px;
  background-color: var(--bg-primary);
  color: white;
}
.questionImageWrapper {
  height: 350px;
  width: 100%;
}
.btnCancel {
  background-color: #edebf1;
  color: var(--bg-primary);
  font-weight: bold;
  border: none;
}
.btnNext {
  background-color: var(--bg-primary);
  color: white;
  font-weight: bold;
  border: none;
}
/* ........................<Assesment route css ends>.............................. */

/* <>..............................................<Grade Book>.............................,. */
/* .table-striped tbody tr:nth-of-type(odd) {
  background-color: red !important;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: white;
} */
/* .teacherObservation {
} */
/* .observationWrapper {
  height: 300px;
  max-height: 250px;
  overflow-y: auto;
} */
.observationHeader {
  border-top-right-radius: 7px;
  height: 50px;
  border-top-left-radius: 7px;
  background-color: var(--bg-primary);
  display: flex;
  align-items: center;
  color: white;
  /* justify-content: center; */
}
.observationContent {
  height: auto;
  overflow-y: auto;
  max-height: 60vh;
  overflow-y: auto;

}
.gradesTable thead {
  border-bottom: 1px solid #d1cccc;
}
.gradesTable {
  min-height: 80vh;
}
.popupWrapper {
  position: absolute;
  display: flex;
  padding: 8px 5px;
  box-shadow: -6px 0px 22px 0px #191e4566;
  background: white;
  flex-direction: column;
  gap: 2px;
  width: 120px;
  height: auto;
  z-index: 100;
  border-radius: 8px;
  /* top: 8px; */
}
.optionLists span {
  font-size: 13px;
}
.optionLists:hover {
  background-color: #66666626;
}
.iconWrapper {
  height: 40px;
  width: 40px;
  border-radius: 6px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gradeTableDropdown {
  position: absolute;
  right: 0;
  top: 40px;
}
/* <>..............................................<Grade Book>.............................,. */

/* <>..................................<Exams route css starts>..................................... */
.iconWithBg {
  background-color: #6f6795;
  padding: 5px;
}

.examChip {
  padding: 5px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  font-size: 10px;
}
/* .firstChild {
  background-color: #ede7ff;
  color: --bg-primary;
}
.secondChild {
  background-color: #e5f3f2;
  color: #30ada1;
} */
.examChipsWrapper > :nth-child(3) {
  background-color: #e4effc;
  color: #3d87dc;
}
.examChipsWrapper > :nth-child(2) {
  background-color: #e5f3f2;
  color: #30ada1;
}
.examChipsWrapper > :nth-child(1) {
  background-color: #e4effc;
  color: #3d87dc;
}
.examSvgsText span {
  color: #fff;
  text-transform: capitalize;
  font-size: 10px;
}
.examSvgsText p {
  color: black;
  font-size: 10px;
  font-weight: 500;
  color: #fff;
}
.examDataWrapper {
  box-shadow: 0px 11px 24px 0px #7676762e;
  border-radius: 8px;
  background: radial-gradient(circle, #585085, #241763);
  color: #fff;
}

/*  EXAM MODAL CSS */
.examModalWrapper {
  border-radius: 22px;
}
.examModalHeader {
  height: 50px;
  background-color: #edebf1;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 20px;
  /* padding: 0 15px; */
}
.examModalHeader p,
h4 {
  padding: 0;
  margin: 0;
}
.examModalHeader .iconWrapper {
  width: 38px;
  height: 38px;
  border-radius: 10px;
  background-color: var(--bg-primary);
  color: white;
}
.Examcounter {
  height: 35px;
  display: flex;
  width: 50%;
  background-color: white;
  border-radius: 8px;
  padding: 0 6px;
  justify-content: space-between;
  border: 1px solid #bab6c8;
  align-items: center;
}
.counterIconWrapper {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ebebeb;
  border-radius: 10px;
  border: 1px solid #bab6c8;
  cursor: pointer;
}
.btnFooter {
  width: 100%;
  padding: 5px 25px;
  border-radius: 6px;
  border: none;
}
.modalWrapper label {
  font-size: 12px;
}
.modalWrapper input {
  font-size: 12px;
}
.modalWrapper input:focus-within,
.modalWrapper select:focus-within {
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #e6e6e6;
}
.modalWrapper textarea:focus-within {
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #e6e6e6;
}
#classSelect option:hover {
  background-color: red !important;
}

/* <>..................................<Exams route css ends>..................................... */

/* Overwriting React Modal backdrop */
.ReactModal__Overlay {
  background-color: rgb(113 110 110 / 75%) !important;
  z-index: 100;
}
.ReactModal__Content {
  background-color: #fff;
  background: white;
  opacity: 100 !important;
  width: 60% !important;
  padding: 0 !important;
  box-shadow: 0px 8px 15px 0px #35353524;
  border-radius: 20px !important;
  overflow-x: hidden !important;
  /* z-index: 999999999; */
}
.addQuestionIconWrapper {
  height: 30px;
  width: 30px;
  border-radius: 10px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.userAvatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 2px; */
  border: 2px solid #ffffff;
}
.userAvatar img {
  border-radius: 50%;
}
.addParticipantBtn {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dotted #463c77;
  background-color: #c4afff4d;
  cursor: pointer;
}
.locationInput {
  border: none !important;
}
.userAvatars {
  display: flex;
  gap: -5px;
  position: relative;
}
.userAvatars .userAvatar.lists {
  margin-left: -15px;
}
.userAvatars .userAvatar.mask {
  margin-left: -15px !important;
  filter: blur(0.3);
}
.avatarBackdrop {
  position: absolute;
  background-color: #283b43;
  height: 100%;
  width: 100%;
  opacity: 0.7;
}
.avatarBackdrop span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  color: white;

  font-size: 12px;
}
.userAvatars .userAvatar {
  height: 32px;
  width: 32px;
}
/* <>...........<Add Question Modal CSS> */
.questionBarProgress {
  width: 100%;
  height: 8px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 3px;
  gap: 4px;
  align-items: center;
  position: relative;
  background-color: #e0e0e0;
  overflow: hidden;
}
.questionBarProgress .dot {
  height: 4.68px;
  width: 4.68px;
  border-radius: 50%;
  background-color: #51477f;
  position: relative;
  z-index: 9999;
}
/* .dot:nth-child(1) {
  background-color: white;
} */
.dotWhite {
  background-color: white !important;
}
.questionDotOuter {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--bg-primary);
  cursor: pointer;
  padding: 2px;
}
.questionDotInner {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: var(--bg-primary);
}

.questionBarProgress .progressBar {
  position: absolute;
  background-color: #51477f;
  height: 100%;

  border-radius: 15px;
  left: 0;
}

/* <>...........<Add Question Modal CSS> */

/* <>........................<Community Route all css including components> */
.communityWrapper {
  box-shadow: 0px 11px 24px 0px #8a89892b;
  background-color: #efefef;

  /* margin-top: 14px; */
}

.singleChatWrapper {
  height: 83vh;
  border-radius: 15px;
}
/* .SingleChat {
  background-color: red;
} */
.ChatsWrapper {
  height: 100%;
  border-radius: 15px;
}

.messageWrapper {
  height: 90%;
  max-height: 90%;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 15px;
}
.SearchWrapper {
  width: 100%;
  height: 34px;
  border-radius: 7.4px;
  background-color: #edebf1c9;
  padding: 2px 10px;
  display: flex;
  gap: 2px;
  align-items: center;
}
.SearchWrapper input[type="text"] {
  outline: none;
  border: none;
  border-radius: 7.4px;
  color: var(--bg-primary) 63;
  width: 90%;
  background-color: transparent;
  font-size: 12px;
}
.SearchWrapper .searchIcon {
  height: 20px;
  width: 20px;
  color: var(--bg-primary);
}
.chatWrapper img {
  height: 38px;
  width: 37px;
  min-width: 37px;
  border-radius: 7.2px;
  object-fit: cover;
}
.chatWrapper .userName {
  font-size: 12px !important;
  font-weight: bold;
}
/* Text Chip */
.textChip {
  padding: 2px 5px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  border: none;
  font-size: 10px;
}
.chatWrapper .message {
  padding: 0;
  margin: 0;
  font-size: 11px;
  max-width: 96%;
  overflow: hidden;
  max-height: 32px;
  background-color: white;
}
.chatWrapper .chatFooter {
  font-size: 9px !important;
}
.chatFooter .icon {
  height: 13px;
  width: 13px;
  color: #9fa7be;
}
.chatWrapper {
  border-top: 0.1px solid #afb8cf !important;
  /* border-bottom: 0.1px solid #afb8cf !important; */
  padding: 10px 15px;
  /* margin-top: 5px; */
}
.customDivider {
  background-color: #afb8cf;
  padding: 0.1px 0;
}
.customDropdown {
  background-color: transparent;
  border: none !important;
}
/* Single Chat */
.chatHeader {
  height: 10%;
  background-color: var(--bg-primary);
  color: white;
  align-items: center;
  padding: 0 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.chatHeader .userDp {
  height: 38px;
  width: 37px;
  min-width: 37px;
  border-radius: 7.2px;
  object-fit: cover;
  border: 1px solid white;
  padding: 1px;
}
.chatHeader .chatUserName {
  font-size: 14px;
}
.messages {
  height: 90%;
  max-height: 90%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 15px;
}
.chatFooterMain {
  height: 10%;
  background-color: #edebf1;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  align-items: center;
  border: 1px solid #d4d1df;
  padding: 0 15px;
  color: white;
  /* justify-content: space-between; */
  gap: 5px;
}
.nameAvatar {
  width: 37px;
  height: 37px;
  border-radius: 7.24px;
  border: 0.5px solid var(--bg-primary) 80;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1eff4;
  font-weight: bold;
  color: var(--bg-primary);
  text-transform: uppercase;
}
.roundedNameAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 0.5px solid var(--bg-primary) 80;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1eff4;
  font-weight: bold;
  color: var(--bg-primary);
  font-size: 11px;
  text-transform: uppercase;
}

.emojiWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-primary);
  border-radius: 50%;
  padding: 8px;
  color: white !important;
}
.addFileChatWrapper {
  background-color: var(--bg-primary);
  color: white;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chatFooterMain input {
  border: none;
  outline: none;
  width: 100%;
}
.chatInputWrapper {
  border: 1px solid #d4d1df;
  height: 40px;
  background-color: white;
  border-radius: 24px;
  padding: 0 10px;
  /* flex-grow: 1; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* CHAT GPT CHAT CODE */
.chat-box {
  display: flex;
  flex-direction: column;
}

.message {
  max-width: 70%;
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 8px;
}

.sent {
  align-self: flex-end;
  background-color: #dcf8c6; /* Example background color for sent messages */
}

.received {
  align-self: flex-start;
  background-color: #e2e2e2; /* Example background color for received messages */
}

.message-content {
  font-size: 14px;
}

.message-timestamp {
  font-size: 12px;
  color: #777;
}

/* CHAT GPT CHAT CODE */

/*Chat css */
.sentMessageBubble {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.chatBubbleSent {
  height: auto;
  padding: 15px 10px;
  background-color: var(--bg-primary);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  color: white;
}
.RecievedMessageBubble {
  background-color: #edebf1 !important;
  height: auto;
  padding: 15px 10px;
  background-color: #fff;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top-left-radius: 15px;
  color: black;
}
/* Bootstrap dropdown icon */
.dropdown-toggle::after {
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-down"><path d="m6 9 6 6 6-6"/></svg>');
  position: relative;
  top: 4px;
}
.dropdown-toggle::after {
  border: none !important;
  vertical-align: 0 !important;
}
.chatTimeStamprecieved {
  color: #afb8cf;
  font-size: 12px;
}
.chatTimeStampsent {
  position: absolute;
  right: 2px;
  color: #afb8cf;
  font-size: 12px;
  white-space: nowrap;
  top: 100%;
}
.chatsMain {
  width: 35%;
  height: 83vh;
}
.chatFilterDropdownLists {
  font-size: 13px;
}
.chatFilterDropdownLists:hover {
  background-color: #edebf1;
  border-radius: 6px;
}

/*Chat css */
/* <>........................<Community Route all css including components> */

/* <>............................<Library Route css starts>............................ */
.libraryCard {
  border-radius: 15px;
}
.libraryCard .libraryImageWrapper {
  height: 250px;
  width: 100%;
}
.libraryImageWrapper img {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}
.authorSection {
  color: #7a7a7a;
  font-size: 12px;
}
.bookDetailsModalHeader {
  background-color: #edebf1;
}
.bookDetails label {
  font-size: 12px;
  color: #7a7a7a;
}
.bookModelDescription {
  height: 120px;
  max-height: 120px;
  white-space: wrap;
  overflow: hidden;
  font-size: 14px;
  color: #7a7a7a;
}
.bookDetailsFooter .btnDownload {
  width: auto;
  padding: 10px 20px;
  background-color: var(--bg-primary);
  color: white;
  border-radius: 6px;
  border: none;
  font-size: 12px;
}
.bookDetailsFooter .btnCancel {
  width: auto;
  padding: 10px 20px;
  color: var(--bg-primary);
  border-radius: 6px;
  border: none;
  font-size: 12px;
}

/* <>............................<Library Route css ends>............................ */
/* <>...........................<ChatBox css starts>..................................... */

.chatbotIconWrapper {
  position: fixed;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  right: 20px;
  bottom: 2%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chatClosed {
  background-color: var(--bg-primary);
  box-shadow: -10px 4px 42px 0px rgba(0, 0, 0, 0.75);
}
.chatOpened {
  background-color: #ffffff;
  box-shadow: 0px 11px 40px 0px #44444463;
}
.chatOpened:hover {
  background-color: var(--bg-primary);
  box-shadow: 0px 11px 40px 0px #44444463;
}
.chatbotIconWrapper.chatOpened:hover .closeIcon {
  color: white; /* Change 'fill' to the appropriate property depending on your SVG */
}
.chatbox {
  position: fixed;
  right: 20px;
  bottom: 11%;
  border-radius: 15px;
  box-shadow: 0px 20px 40px 0px #2f2e2e40;
  z-index: 9999;
  height: 450px;
  width: 350px;
  display: flex;
  flex-direction: column;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  opacity: 1;
  background-color: rgb(24, 23, 23);
  z-index: 9998;
  background-color: rgba(0, 0, 0, 0.6);
}

.chatbotHeader {
  display: flex;
  align-items: center;
  gap: 8px;
  background: var(--bg-primary);
  height: 13%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 5px 10px;
}
.chatbotLogoWrapper {
  height: 40px;
  width: 40px;
  overflow: hidden;
  padding: 3px;
  border: 1px solid white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chatbotLogoWrapper img {
  object-fit: contain;
}
.chatbotHeader h6 {
  font-size: 14px;
}
.chatbotHeader p {
  font-size: 12px;
}
.onlineDot {
  height: 8px;
  width: 8px;
  background-color: green;
  border-radius: 50%;
}
.chatbox .chats {
  height: 75%;
  max-height: 75%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;
  background: white;
  opacity: 100%;
}
.chatbox .footer {
  height: 12%;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
}
.chatbox .footer input {
  width: 100%;
  outline: none;
  border: none;
  resize: none;
}
.chatbotAvatarWrapper {
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #edebf1;
  padding: 3px;
  overflow: hidden;
}
.chatbotAvatarWrapper img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.chatBotSentMessages {
  height: auto;
  padding: 6px 5px;
  background-color: var(--bg-primary);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  color: white;
  font-size: 15px;
}
.chatboxRecievedMessages {
  background-color: #edebf1 !important;
  height: auto;
  padding: 6px 5px;
  background-color: #fff;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top-left-radius: 15px;
  color: #241763;
  font-size: 15px;
}

/* <>...........................<ChatBox css ends>..................................... */
/* <>..............................<Account settings and its coponent css> ..................*/
.userProfileWrapper {
  background-color: white;
  box-shadow: 0px 20px 40px 0px #5d5d5d14;
  border-radius: 30px;
  height: auto;
}
.userProfileWrapper .avatarWrapper {
  position: relative;
  height: 70px;
  width: 70px;
  padding: 5px;
  border: 2px solid #f6f6f6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatarWrapper img {
  height: 100%;
  width: 100%;
}
.editAvatarImage {
  position: absolute;
  background-color: var(--bg-primary);
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  right: -7px;
  padding: 5px;
  border: 2px solid #ffffff;
  bottom: 2px;
  cursor: pointer;
}
.userClassMeta p {
  font-size: 11px;
}
.userClassMeta h6 {
  font-size: 14px;
}
.userGenInfoWrapper {
  background-color: white;
  box-shadow: 0px 20px 40px 0px #5d5d5d14;
  border-radius: 30px;
  height: auto;
}
/* <>..............................<Account settings and its coponent css ends> ..................*/
/* Bilal css */
.select-bg {
  color: #aeb6bd;
}
/* account settings css */
.input-gender::placeholder {
  color: #c7cdd2;
}
.input-gendertwo::placeholder {
  color: #c7cdd2;
}
.input-birthday {
  margin-top: 36px;
  height: 48px;
  color: #c7cdd2;
}
.drop-btn {
  background-color: #f9fafb !important;
  width: 42px;
  height: 35px;
  border: 1px solid #a8acb166;
  /* border-radius: 6px, 0px, 0px, 16px; */
}
.input-gender {
  width: 100%;
  outline: none;
  border: 1px solid #a8acb166;
  height: 35px;
  color: #c7cdd2;
  /* border-radius: 5px; */
}
.input-cx {
  width: 100%;
  outline: none;
  border: 1px solid #a8acb166;
  height: 35px;
  color: #c7cdd2;
}
.input-gendertwo {
  width: 100%;
  /* margin-top: 38px; */
  outline: none;
  border: 1px solid #a8acb166;
  height: 46px;
  color: #a8acb1;
  /* border-radius: 5px; */
}
.input-height {
  margin-top: 38px;
}
.icon-drop {
  color: #a8acb1;
}
.btn-setting {
  width: 208px;
  height: 48px;
  background-color: var(--bg-primary);
  color: white;
  border-radius: 24px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.setting-para {
  color: #7a7a7a;
}

/* Bilal css ends here */

/* awais css */
.Salary_card {
  padding: 15px;
  max-height: 85vh;
  overflow: auto;
}
.Salary_Report {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 20px;
}
.Salary_Report .Student_Data {
  gap: 50px;
  display: flex;
  /* padding: 5px; */
}
.Exam_style {
  color: #7a7a7a;
  font-size: 14px;
}
.font_color {
  font-weight: bold;
}
.Salary_Data,
.Salary_Heading {
  border: 1px solid hsl(0, 0%, 87%);
  padding: 5px;
}
.Salary_Data {
  text-align: center;
  font-size: 12px;
}
.studentInfo {
  font-size: 15px;
}
.Salary_Heading {
  font-size: 14px;
  background-color: var(--bg-primary);
  color: #dddddd;
  text-align: center;
}
tr:nth-child(even) {
  background-color: var(--bg-primary) 1a;
}
.Table_Card {
  margin-top: 20px;
  border-radius: 10px;
  overflow-x: auto;
}

.Report_style {
  text-align: center;
  background-color: #edebf1;
  font-family: "PoppinsReg";
  padding: 20px 0;
  font-weight: bold;
  font-size: 20px;
}

.Report_button {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 25px;
}
/* class management */
.classManagementDate {
  font-size: 14px;
}
.Time_Table {
  background-color: white;
  /* padding: 20px; */
  border-radius: 15px;
}

.font-size14 {
  font-size: 14px;
}

.table .Shedule {
  width: 250px;
}

.font-size13 {
  font-size: 13px;
}

.table {
  margin-top: 50px;
  border-top: hidden;
}

.table td,
.table th {
  padding: 0.75rem;
  border-left: none;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  width: 144px;
  height: 72px;
}

.table th:last-child {
  border-right: hidden;
}

.table td:last-child {
  border-right: none;
}

.exact_time {
  border-bottom: hidden;
  border-right: hidden;
}
.calender_left {
  /* height: 30px; */
}

.left_angle {
  background-color: #f4f4f5;
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.right_angle {
  background-color: #f4f4f5;
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.center_calender {
  background-color: #f4f4f5;
}

.shades {
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 30px;
}

.text_color {
  font-size: 19px;
}

.table .Shedule {
  border-left: hidden;
}
/*  */

.Year_table {
  background-color: var(--bg-primary);
  color: white;
  border-radius: 5px;
  width: 60px;
  text-align: center;
}

/* awais css ends*/
/* Media Queries */
/* <>.....................................<BILAL CSS FOR TEACHER COMPONENTS STARTS>......................................... */

.seven-class {
  background-color: #52afa626;
  color: #30ada1;
  border-radius: 8px;
  text-align: center;
}

.seven-class p {
  font-size: 12px;
  font-weight: bold;
}

.section p {
  font-size: 12px;
  font-weight: bold;
}

.section {
  background-color: #4e97ea26;
  color: #3d87dc;
  border-radius: 7px;
}

.text-class {
  color: #979797;
  font-size: 12px;
  font-weight: lighter;
}

.btn-class {
  width: 200px;
  height: 40px;
  background-color: #edebf1;
  color: var(--bg-primary);
  border-radius: 10px;
  border: none;
  font-size: 12px;
  font-weight: bold;
}

.english-para {
  font-size: 13px;
  color: #979797;
  font-style: italic;
}

/* inbox component  */
.img-inbox {
  width: 30px;
  height: 30px;
  border-radius: 6px;
}

.inbox-text {
  font-size: 13px;
  font-weight: bold;
}

.inbox-para {
  font-size: 10px;
  color: #636363;
}

.inbox-time {
  font-size: 9px;
  color: #afb8cf;
}

.msg:hover {
  background-color: #edebf1cc;
  cursor: pointer;
}
/* Lesson plan css goes here */
/* bilal css for lesson plan  page  */
.textarea {
  width: 100%;
  height: 100px;
  outline: none;
  border: 1px solid #a8acb166;
  color: #c7cdd2;
}

.textarea::placeholder {
  color: #c7cdd2;
}

.btn-update {
  width: 164px;

  height: 40px;

  background-color: var(--bg-primary);
  color: white;
  border: none;
  border-radius: 6px;
}

.btn-cancel {
  background-color: #edebf1;
  color: var(--bg-primary);
  width: 120px;
  height: 40px;
  border: none;
  border-radius: 6px;
  font-weight: bold;
}

/* <>..........................<Custom component code starts>...................... */
.btnWithIcon {
  padding: 4px 8px;
  border-radius: 8px;
  color: white;
  width: auto;
  white-space: nowrap;
  border: none;
}
/* <>..........................<Custom component code ends>...................... */

/* Curriculum Design */
.curriculumNavWrapper {
  box-shadow: 0px 20px 40px 0px #5d5d5d14;
}
.curriculumNavItems {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  border-radius: 7px;
}
.curriculumNavItems:hover {
  background-color: #edebf1;
  cursor: pointer;
}
.activecurriculumNavItems {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  border-radius: 7px;
  background-color: #edebf1;
  cursor: pointer;
}
.curriculumContent {
  font-size: 15px;
  max-height: 100vh;
  overflow-y: auto;
  height: 70vh;
  /* overflow: hidden; */
}

/* <>............................<Attendance Buttons> ........................................*/
.ppBtnActive {
  background-color: #3dc079;
  color: white;
  border: none;
  outline: none;
  height: 40px;
  width: 40px;
  border-radius: 8px;
}
.ppBtn {
  background-color: white;
  color: #3dc079;
  height: 40px;
  width: 40px;
  border-radius: 8px;
  border: 1px solid #3dc079;
  outline: none;
}
.poBtnActive {
  background-color: #3d87dc;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 8px;
  outline: none;
  height: 40px;
  width: 40px;
  border-radius: 8px;
}
.poBtn {
  background-color: white;
  color: #3d87dc;
  height: 40px;
  width: 40px;
  border-radius: 8px;
  border: 1px solid #3d87dc;
  outline: none;
}
.aBtnActive {
  background-color: #f2335d;
  color: white;
  border: none;
  outline: none;
  height: 40px;
  width: 40px;
  border-radius: 8px;
}
.aBtn {
  background-color: white;
  color: #f2335d;
  height: 40px;
  width: 40px;
  border-radius: 8px;
  border: 1px solid#F2335D;
  outline: none;
}
.BtnActive {
  background-color: #ffb930;
  color: white;
  border: none;
  outline: none;
  height: 40px;
  width: 40px;
  border-radius: 8px;
}
.lBtn {
  background-color: white;
  color: #ffb930;
  /* padding: 10px; */
  height: 40px;
  width: 40px;
  border-radius: 8px;
  border: 1px solid#FFB930;
  outline: none;
}
.lBtnActive {
  background-color: #ffb930;
  color: white;
  padding: 10px;
  border-radius: 8px;
  border: none;
  outline: none;
  height: 40px;
  width: 40px;
  border-radius: 8px;
}
.generateAtt button {
  font-weight: bold;
}
.attendanceUser img {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  object-fit: cover;
}
.dateInputWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e4e7eb;
  padding: 0 3px;
  border-radius: 7.2px;
  width: 100%;
  height: 40px;
}
.DatePicker {
  border: none !important;
  height: 100%;
  flex: 1;
}
.DatePicker:focus {
  border: none !important;
  outline: none !important;
}
.iconbox {
  height: 100%;
  width: 30px;
  border-left: 1px solid #e4e7eb;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.rounded-borders {
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px;
  background-color: transparent;
}
/* <>............................<>.................................... */
.observationModalheader {
  border-top-right-radius: 7px;
  height: 100%;
  border-top-left-radius: 7px;
}
/* <>............................<Attendance Buttons> ........................................*/

/* <>.............................<Parent CSS Starts Here>....................................<> */
.childChip {
  width: 200px;
  padding: 20px 9px;
  border-radius: 15px;
  color: var(--bg-primary);
  background-color: white;
}
.childChipActive {
  background-color: var(--bg-primary);
  width: 200px;
  padding: 20px 9px;
  border-radius: 15px;
  color: white;
  border: 0.8px solid #2417634d;
}
.childChip h6 {
  /* color: var(--bg-primary) !important; */
  font-weight: 400 !important;
  font-size: 14px !important;
}
.childChip span {
  /* color: #7a7a7a; */
  color: var(--bg-primary);
  font-family: poppins;
  font-style: italic;
}
.childChipActive h6 {
  /* color: var(--bg-primary) !important; */
  font-weight: 400 !important;
  font-size: 14px !important;
}
.childChipActive span {
  /* color: #7a7a7a; */
  color: rgb(170, 169, 169);
  font-family: poppins;
  font-style: italic;
}
.childAvatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.childAvatar img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}
/* upcomingClasses */
.upComingClassMain {
  background-color: white;
  /* width: 50%; */
  padding: 10px;
}
.upComingHeading {
  font-size: 17px !important;
  margin-left: 5px;
}
.clock_Dp {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.upcomingClassCardImage {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.upcomingClassChips .test:nth-child(1) {
  background-color: #ede7ff;
  color: #8d66fe;
}
.upcomingClassChips .test:nth-child(2) {
  color: #30ada1;
  background-color: #e5f3f2;
}
.upcomingClassChips .test:nth-child(3) {
  color: #3d87dc;
  background-color: #e4effc;
}
.test {
  font-size: 11px;
  font-weight: bold;
  border-radius: 7px;
  text-align: center;
  white-space: nowrap;
}
.cardMini {
  /* width: 30% !important; */
}
.userDots {
  height: 15px;
  width: 15px;
  border-radius: 50%;
}
/* .analyticsCard {
  height: 535px;
} */
/* upcomingClasses */
/* <>.............................<Parent CSS ends Here>....................................<> */

@media (max-width: 990px) {
  .weakform-text {
    font-size: 14px !important;
    /* Adjust the font size for medium screens */
  }
}

@media (max-width: 768px) {
  .weakform-text {
    font-size: 12px !important;
    /* Adjust the font size for small screens */
  }
}

@media (max-width: 1024px) {
  .weakform-text {
    font-size: 13px !important;
    /* Adjust the font size for small screens */
  }
}
/* <>.....................................<BILAL CSS FOR TEACHER COMPONENTS ENDS>......................................... */
/* sidebar media queires */
@media (max-width: 600px) {
  .sidebarWrapperSm,
  .sidebarOuterSm {
    display: none;
  }
  .mneuIcon {
    display: block;
  }
}
@media (max-width: 993px) {
  /* .sidebarWrapperSm,
  .sidebarOuterSm {
    width: 8%;
  } */
  .sidebarOuterLg {
    width: 28%;
  }
  .sidebarWrapperLg {
    width: 22%;
  }
  .sidebarOuterSm {
    width: 12%;
  }
  .sidebarWrapperSm {
    width: 10%;
  }
}
@media (min-width: 600px) {
  .sidebarWrapperLg,
  .sidebarWrapperSm,
  .sidebarOuterLg,
  .sidebarOuterSm {
    display: flex !important;
  }
}
@media (min-width: 600px) {
  .miniSidebar {
    display: none;
  }
  .mneuIcon {
    display: none;
  }
}
/* sidebar media queires ends here.*/

/* ........................<Course Route Media queries>........................... */
/* @media (max-width: 860px) {
  .filterationCourse {
    width: 50%;
  }
  .coursesWrapper {
    width: 50%;
  }
  .filterationCourse {
    display: none;
  }
  .coursesWrapper {
    width: 100%;
  }
  .courseLists {
    justify-content: center;
  }
}
@media (min-width: 860px) {
  .filterIcon {
    display: none;
  }
}
@media screen and (min-width: 860px) and (max-width: 1400px) {
  .filterationCourse {
    width: 40%;
  }
  .coursesWrapper {
    justify-content: center;
  }
  .courseCard {
    width: 30rem !important;
  }
} */
/* ........................<Course Route Media queries ends>........................... */
/* CHATBOT MEDIA QUERIES */
@media (max-width: 600px) {
  .chatbox {
    margin: auto auto;
  }
  /* .routesWrapper {
    padding: 15px 5px;
  } */
}
